.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
}

.profile-img,
.profile-img img {
    height: 120px;
}

.profile-img .ant-upload {
    background: #fff;
}

.profile-img .ant-upload.ant-upload-drag .ant-upload {
    padding: 0px;
}

.ant-form-item-explain-error {
    font-size: 11px;
}

.profile h1 {
    font-size: 22px;
    font-weight: 600;
}

.profile .profile-btn {
    font-size: 12px;
    background-color: blue;
    color: #fff;
    border-radius: 4px;
    border: 1px solid blue;
}

.profile .reset-btn {
    font-size: 12px;
    background-color: grey;
    color: #fff;
    border-radius: 4px;
    border: 1px solid grey;
}

.profile-modal .ant-input {
    font-size: 12px;
}

.profile-modal .ant-input:placeholder-shown {
    font-size: 12px;
    text-overflow: ellipsis;
}

.profile-modal {
    width: 420px !important;
}

.size_logo {
    margin-top: -7px;
    border-radius: 10px;
}

.profile-img h1 .anticon {
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left: 230px;
    top: -5px;
    z-index: 99;
    background-color: rgb(210, 210, 210);
    cursor: pointer;
}

.reset {
    color: blue;
    text-decoration: underline;
    font-size: 12px;
    position: relative;
    top: -13px;
    cursor: pointer;
}

.btn-part .ant-form-item {
    margin: 0px !important;
}