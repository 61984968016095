.side-bar {
    background-color: #F9FAFC;
    height: 100vh !important;
    width: 70%;
    position: sticky;
    top: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-bar h4 {
    color: #838488;
    font-size: 11px;
    margin-bottom: 20px;
}

.footer-bar h5 {
    font-size: 11px;
    background-color: #EEF2F9;
    border-radius: 4px;
    color: #435371;
    margin: 10px;
    padding: 4px;
    white-space: nowrap;
}

.menu-bar .anticon {
    font-size: 17px;
    color: #838488;
}

.footer-bar .anticon {
    margin-right: 3px;
}

.size_profile {
    width: 50px;
    height: 40px;
    border-radius: 5px;
}

.nav-active .pointer span {
    color: #000;
}

.nav-active .pointer h4 {
    color: #000;
    font-weight: 550;
}