body {
  overflow-x: hidden;
  font-family: 'Poppins' !important;
}

* {
  padding: 0px;
  margin: 0px;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.body-part {
  padding-right: 10px;
}

.pointer {
  cursor: pointer;
}

.input-span {
  position: absolute;
  top: -30px;
  color: red;
  left: 50px;
}

.no-data {
  font-size: 19px;
  text-align: center;
  margin-left: 20px;
}

.no-datas {
  margin-left: 400px;
  font-size: 16px;
  margin-top: 250px;
  font-weight: 600;
}

/* scrollbar start*/

::-webkit-scrollbar {
  width: 3px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3157F6;
  border-radius: 10px;
}

/* scrollbar End*/

@media only screen and (max-width: 1237px) {

  .tab-main h4,
  .tab-main h5 {
    display: none;
  }

  .menu-bar h4 {
    font-size: 9px !important;
  }
}